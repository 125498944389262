<template lang="pug">
  article.catalog-card.default-card
    .combined(data-placeholder v-if="!data")
      .frame
        a.preview
          .clp
        .info
          .product
            a.clp.w-10 &nbsp;

    .combined(v-else)
      .frame
        nuxt-link.preview(
          :to="link"
          v-click="() => $amplitude.trackProduct(trackProps)")
          img.image(:src="image")

        .info
          .product
            nuxt-link(
              :to="link"
              v-text="name"
              :title="name"
              v-click="() => $amplitude.trackProduct(trackProps)")

</template>

<script>

export default {
  name: 'CatalogCard',

  props: {
    data: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: 'brand'
    },
    trackProps: {
      type: [Object, String],
      default: ''
    }
  },

  computed: {
    isProduct () {
      return this.type === 'product'
    },
    image () {
      return this.isProduct ? this.data.photo_url : this.data.main_image_url
    },
    name () {
      return this.isProduct ? this.data.product_name : this.data.name
    },
    link () {
      return this.isProduct
      ? this.$links.product({
          id: this.data.product_id,
          name: this.data.product_name,
          brand: this.data.brand_name
        })
      : this.$links.brand({
          id: this.data.id,
          name: this.data.name
        })
    }
  },

  methods: {
    getProductLink (args = {}) {
      const query = {
        'q[tag_ids]': this.$route.query['q[tag_ids]'] || undefined
      }
      if (this.selectSkuByImageUrl) query.i = this.$links.hash(this.coverThumbnailUrl)
      return this.$links
      .product({
        id: this.data.product_id,
        name: this.data.product_name,
        brand: this.data.brand_name,
        query,
        ...args
      })
    }
  }
}
</script>

<style lang="scss" scoped>
article.catalog-card {
  $oos: #999;

  .combined {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    height: 100%;
    overflow: hidden;
    border: 2px solid transparent;
    &:not([data-placeholder]) {
      &:hover {
        border-color: $--color-primary;
        .frame {
          .actions {
            display: block;
          }
          a {
            -webkit-line-clamp: inherit !important;
            line-clamp: inherit !important;
          }
        }
      }
    }
    > * {
      min-height: 0;
    }
    .frame {
      flex: 1;
    }
  }
  .frame {
    background: white;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    position: relative;
    gap: 8px;
    padding-bottom: 50px;
  }
  .preview {
    aspect-ratio: .9;
    transition: filter .2s ease-in-out;
    .clp {
      width: 100%;
      aspect-ratio: 1;
    }
    .image {
      width: 100%;
      aspect-ratio: 1;
      object-fit: contain;
      display: block;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px 12px 14px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0 0 6px 6px;
    background: linear-gradient(0deg, white 95%, transparent 100%);
    > * {
      min-height: 0;
    }
    .product {
      font-size: 14px;
      font-weight: $--font-weight-bold;
      line-height: 18px;
      min-height: 36px; // 2 lines
      a {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-clamp: 2;
        overflow: hidden;
      }
    }
  }
}
</style>
