<template lang="pug">
header.header-bar(
  :class="{ 'header-bar--expanded': expanded }"
  @click="expanded = false")
  .header-bar__layout
    button.header-bar__toggler.hidden-sm-and-up(@click.prevent.stop="toggle")
      i.bx.bx-menu(v-if="!expanded")
      i.bx.bx-x(v-else)

    nuxt-link.header-bar__logo(:to="{ name: 'index' }" title="Pod")
      img(src="/img/landing/logo.svg" width="95" height="44" alt="Pod")

    .header-bar__divider.hidden-xs-only
    .header-bar__spacer.hidden-sm-and-up

    .header-bar__menu.hidden-xs-only(@click.stop)
      nuxt-link.header-bar__menu-item(:to="{ name: 'about' }" title="About Pod") About

      el-dropdown
        span.header-bar__menu-item Services
          i.bx.bx-chevron-down
        el-dropdown-menu(slot="dropdown")
          el-dropdown-item
            nuxt-link(:to="{ name: 'services-brands' }" title="POD for Brands") POD for Brands
          el-dropdown-item
            nuxt-link(:to="{ name: 'services-retailers' }" title="POD for Retailers") POD for Retailers

      nuxt-link.header-bar__menu-item(:to="{ name: 'pricing' }" title="Pricing") Pricing
      nuxt-link.header-bar__menu-item(:to="{ name: 'products' }" title="Catalog") Catalog

      el-dropdown
        span.header-bar__menu-item Media
          i.bx.bx-chevron-down
        el-dropdown-menu(slot="dropdown")
          el-dropdown-item
            nuxt-link(:to="{ name: 'bloomberg-2024' }" title="Bloomberg 2024") Bloomberg 2024
          el-dropdown-item
            nuxt-link(:to="{ name: 'press' }" title="Press Releases") Press Releases
          el-dropdown-item
            nuxt-link(:to="{ name: 'blog' }" title="Blog") Blog
          el-dropdown-item
            nuxt-link(:to="{ name: 'podcasts' }" title="Podcasts") Podcasts

    .header-bar__user
      user-box

</template>

<script>
import UserBox from './UserBox'

export default {
  components: {
    UserBox
  },

  data () {
    return {
      expanded: false
    }
  },

  watch: {
    $route: {
      handler () {
        this.expanded = false
      }
    }
  },

  methods: {
    toggle () {
      this.expanded = !this.expanded
    }
  }
}
</script>

<style lang="scss" scoped>
.header-bar {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  user-select: none;
  @include media(xs-only) {
    &:before {
      content: '';
      position: fixed;
      background: rgba(black, .25);
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
      pointer-events: none;
      display: block !important;
      transition: opacity .3s ease-in-out;
    }
    &--expanded {
      &:before {
        opacity: 1;
        pointer-events: auto;
      }
      .header-bar__menu {
        opacity: 1 !important;
        pointer-events: auto !important;
      }
    }
    .header-bar__menu {
      top: 100%;
      position: absolute;
      right: 0;
      left: 0;
      z-index: 999;
      opacity: 0;
      background: white;
      padding: 0 12px 16px 60px;
      border-radius: 0 0 16px 16px;
      pointer-events: none;
      display: block !important;
      transition: opacity .3s ease-in-out;
    }
  }
  &__layout {
    background: white;
    display: flex;
    align-items: center;
    gap: 16px;
    height: 64px;
    padding: 0 12px;
    @include media(sm) {
      height: 80px;
      padding: 0 16px;
    }
    @include media(md) {
      gap: 32px;
      height: 96px;
      padding: 0 24px;
    }
    > * {
      min-width: 0;
    }
  }
  &__toggler {
    border: none;
    background: none;
    border: none;
    padding: 12px;
    margin-left: -12px;
    margin-right: -12px;
    cursor: pointer;
    color: $--color-primary-dark;
    &:hover {
      color: $--color-primary;
    }
    i {
      font-size: 28px;
    }
  }
  &__logo {
    img {
      width: auto;
      height: 36px;
      @include media(sm) {
        height: 40px;
      }
      @include media(md) {
        height: 44px;
      }
    }
  }
  &__divider {
    width: 1px;
    height: 32px;
    background: rgba(black, .1);
  }
  &__spacer {
    flex: 1;
  }
  &__menu {
    flex: 1;
    display: flex;
    gap: 16px;
    @include media(sm) {
      gap: 20px;
    }
    @include media(md) {
      gap: 36px;
    }
    &-item {
      font-size: 16px;
      line-height: 120%;
      padding: 8px 0;
      display: flex;
      align-items: center;
      gap: 0px;
      cursor: pointer;
      transition: color .2s ease-in-out;
      &:hover {
        color: $--color-primary;
      }
      i {
        font-size: 20px;
        color: $--color-primary;
      }
    }
  }
  &__user {
    &::v-deep {
      .el-button {
        border: 1px solid $--color-primary-dark;
        background: white;
        color: $--color-primary-dark;
        font-size: 16px;
        line-height: 120%;
        padding: 12px 20px;
        font-weight: $--font-weight-semibold;
        border-radius: 1000px;
        height: auto;
        &--ghost {
          border: none;
          background: white;
          color: $--color-primary-dark;
          padding: 12px 16px;
        }
        &:hover {
          background: white;
          color: $--color-primary;
          border-color: $--color-primary;
        }
        &--primary {
          border-color: $--color-primary;
          background: $--color-primary;
          color: white;
          &:hover {
            background: lighten($--color-primary, 5);
            color: white;
          }
        }
        i {
          font-size: 20px;
        }
      }
    }
  }
}
</style>
