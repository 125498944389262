<template lang="pug">
section.footer-bar
  .pd-container.framed
    .footer-bar__layout
      .footer-bar__about
        nuxt-link(:to="{ name: 'index' }" alt="Pod")
          img(src="/img/landing/symbol.svg" width="64" height="64" alt="Pod")

        .footer-bar__tagline We Deliver Innovation.

        .footer-bar__apps(v-if="isLPDomain || isBuyer || isHeadBuyer || !logged")
          template(v-if="isLPDomain")
            p Check out using our mobile app
              br
              | Download <strong>Pod - Partner</strong>.
            ul
              li
                a(:href="LP_APPSTORE_URL" target="_blank")
                  img(src="/img/download.app-store.svg" width="148" height="44" alt="Download on the App Store")
              li
                a(:href="LP_PLAYSTORE_URL" target="_blank")
                  img(src="/img/download.play-store.svg" width="148" height="44" alt="Get it on Google Play")

          template(v-else-if="isBuyer || isHeadBuyer || !logged")
            p Order using our mobile app.
              br
              | Download <strong>Hey Pod</strong>.
            ul
              li
                a(:href="BUYER_APPSTORE_URL" target="_blank")
                  img(src="/img/download.app-store.svg" width="148" height="44" alt="Download on the App Store")
              li
                a(:href="BUYER_PLAYSTORE_URL" target="_blank")
                  img(src="/img/download.play-store.svg" width="148" height="44" alt="Get it on Google Play")

        .footer-bar__copyrights(v-if="copyrights" v-text="copyrights")
        .footer-bar__version
          input.app-info__branch(type="hidden" :value="branch")
          input.app-info__version(type="hidden" :value="version")

      .footer-bar__menus
        div
          h3 Company
          ul
            li
              nuxt-link(:to="{ name: 'about' }" title="About Pod") About POD
            li
              nuxt-link(:to="{ name: 'press' }" title="Press") Press
            li
              nuxt-link(:to="{ name: 'team' }" title="Meet the Team") Meet the Team
            li
              nuxt-link(:to="{ name: 'terms-of-service'}" title="Terms of Service") Terms of Service
            li
              nuxt-link(:to="{ name: 'privacy'}" title="Privacy Policy") Privacy Policy
            li
              nuxt-link(:to="{ name: 'terms-of-use'}" title="Terms of Use") Terms of Use

        div
          h3 Services
          ul
            li
              nuxt-link(:to="{ name: 'services-retailers' }" title="Pod for Retailers") POD for Retailers
            li
              nuxt-link(:to="{ name: 'services-brands' }" title="Pod for Brands") POD for Brands
              ul
                li
                  nuxt-link(:to="{ name: 'services-brands-express' }" title="Pod Express") Pod Express
                li
                  nuxt-link(:to="{ name: 'services-brands-direct' }" title="Pod Direct") Pod Direct
                li
                  nuxt-link(:to="{ name: 'pod-freight' }" title="Pod Freight") Pod Freight
                li
                  nuxt-link(:to="{ name: 'capital' }" title="Pod Capital") Pod Capital
                li
                  nuxt-link(:to="{ name: 'pod-3pl' }" title="Pod 3PL") Pod 3PL
                li
                  nuxt-link(:to="{ name: 'pod-insights' }" title="Pod Insights") Pod Insights
                li
                  nuxt-link(:to="{ name: 'pod-pax' }" title="Pod Pax") Pod Pax
                li
                  nuxt-link(:to="{ name: 'pod-cost' }" title="Pod C.O.S.T") Pod C.O.S.T Program

        div
          h3 Support
          ul
            li
              nuxt-link(:to="{ name: 'help-center' }" title="Help Center") Help Center
            li
              nuxt-link(:to="{ name: 'pricing' }" title="Pricing") Pricing
            li(v-if="logged && !isBroker")
              el-popover(placement="top")
                div
                  span We moved <strong>Log out</strong> to&nbsp;
                    nuxt-link.footer-bar__settings(:to="toLogOut" title="Settings") Settings
                    | .
                a.footer-bar__logout(href="#" @click.prevent slot="reference") Logout
                  i.ml-1.bx.bx-lock

        div
          h3 Connect
          ul
            li
              nuxt-link(:to="{ name: 'blog' }" title="Blog") Blog
            li
              nuxt-link(:to="{ name: 'podcasts' }" title="Podcasts") Podcasts
            li
              a(:href="SPOTIFY_URL" target="_blank" title="Listen on Spotify") Spotify
            li
              a(:href="FACEBOOK_URL" target="_blank" title="Contact us via Facebook") Facebook
            li
              a(:href="INSTAGRAM_URL" target="_blank" title="Contact us via Instagram") Instagram
            li
              a(:href="LINKEDIN_URL" target="_blank" title="Contact us via LinkedIn") LinkedIn
            li
              a(:href="`mailto:${CONTACT_EMAIL}`" target="_blank" title="Email") Email
</template>

<script>
import { mapGetters } from 'vuex'
import { CONTACT_EMAIL, FACEBOOK_URL, INSTAGRAM_URL, LINKEDIN_URL, SPOTIFY_URL, BUYER_APPSTORE_URL, BUYER_PLAYSTORE_URL, LP_APPSTORE_URL, LP_PLAYSTORE_URL } from '~/settings'

export default {
  data () {
    return {
      CONTACT_EMAIL,
      FACEBOOK_URL,
      INSTAGRAM_URL,
      LINKEDIN_URL,
      SPOTIFY_URL,
      BUYER_APPSTORE_URL,
      BUYER_PLAYSTORE_URL,
      LP_APPSTORE_URL,
      LP_PLAYSTORE_URL,
      copyrights: null
    }
  },

  fetch () {
    this.copyrights = `Copyright © 2017 - ${(new Date()).getFullYear()} Pod Foods Co.`
  },

  computed: {
    ...mapGetters({
      logged: 'authentication/logged',
      isBroker: 'authentication/isBroker',
      isLPDomain: 'authentication/isLPDomain',
      isBuyer: 'authentication/isBuyer',
      isHeadBuyer: 'authentication/isHeadBuyer',
      toSettings: 'authentication/toSettings',
      hideCareersPage: 'configs/hideCareersPage'
    }),

    branch: () => process.env.CURRENT_APP_BRANCH,
    version: () => process.env.CURRENT_APP_VERSION,

    toLogOut () {
      return {
        ...this.toSettings,
        hash: '#logout'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-bar {
  position: relative;
  z-index: 1;
  background: white;
  padding: 48px 0;
  @include media(md) {
    padding: 80px 0;
  }

  &__layout {
    display: flex;
    flex-direction: column;
    gap: 40px;
    @include media(md) {
      flex-direction: row;
      gap: 64px;
    }
    @include media(lg) {
      gap: 80px;
    }
    > * {
      min-width: 0;
    }
  }
  &__about {
    flex: 1;
    font-size: 16px;
    line-height: 160%;
    display: flex;
    flex-direction: column;
    gap: 1em;
    @include media(md) {
      gap: 3.5em;
    }
    @include media(lg) {
      gap: 32px;
    }
  }
  &__tagline {
    font-size: 24px;
    line-height: 135%;
  }
  &__apps {
    p {
      font-size: 16px;
      line-height: 160%;
    }
    ul {
      margin: 2em 0 0 0;
      padding: 0;
      list-style: none;
      display: flex;
      gap: 16px;
      > * {
        min-width: 0;
      }
    }
  }
  &__copyrights {
    font-size: 14px;
    line-height: 160%;
    color: $--color-text-secondary;
  }
  &__version {
    display: none;
  }
  &__menus {
    display: flex;
    gap: 24px;
    font-size: 14px;
    line-height: 160%;
    flex-wrap: wrap;
    @include media(md) {
      gap: 36px;
      font-size: 15px;
    }
    @include media(lg) {
      gap: 80px;
      font-size: 16px;
    }
    > * {
      min-width: 0;
      @include media(sm-down) {
        min-width: 40%;
      }
    }
    h3 {
      font-size: 1.125em;
      color: $--color-primary-dark;
      font-weight: $--font-weight-semibold;
      margin: 0;
    }
    ul {
      list-style: none;
      margin: 24px 0 0 0;
      padding: 0;
      li {
        margin-top: 0em;
        ul {
          margin: 0 0 0 1em;
          li {
            font-size: .9375em;
            @include media(sm-down) {
              font-size: 1em;
            }
          }
        }
      }
    }
    a {
      display: inline-block;
      position: relative;
      overflow: hidden;
      &::before {
        content: '';
        position: absolute;
        background-color: $--color-text-regular;
        height: 1px;
        width: 100%;
        left: 0;
        bottom: 0;
        transform-origin: 100% 50%;
        transform: scaleX(0);
        transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
      }
      &:hover, &:focus {
        color: $--color-primary;
        &::before {
          background-color: $--color-primary;
          transform-origin: 0% 50%;
          transform: scaleX(1);
        }
      }
    }
  }
  &__settings {
    color: $--color-primary;
    font-weight: $--font-weight-bold;
  }
}
</style>
