export const getters = {
  all: (s, g, rs, rg) => {
    const arr = rg['master/remoteSettings']
    if (!arr?.reduce) return {}
    return arr.reduce((res, i) => {
      try {
        res[i.key] = JSON.parse(i.value)
      } catch (e) {}
      return res
    }, {})
  },

  searchTrendingKeywords: (s, g) => g.all.searchTrendingKeywords || [],
  hideCareersPage: (s, g) => !!g.all.ui?.hideCareersPage,
  hidePodGrowFilter: (s, g) => !!g.all.ui?.hidePodGrowFilter,
  trackProductImpressions: (s, g) => !!g.all.ui?.trackProductImpressions,
  growthDeckFields: (s, g) => g.all.growthDeckFields || [],
  vendorCompanyAgreementFields: (s, g) => g.all.vendorCompanyAgreementFields || [],
  vendorCompanyDetailsFields: (s, g) => g.all.vendorCompanyDetailsFields || [],
  vendorCompanyChecklistFields: (s, g) => g.all.vendorCompanyChecklistFields || []
}
