import Vue from 'vue'

export const QUERY_STRUCTURE = {
  term: {
    type: String,
    default: null,
    post: val => val?.trim() || undefined
  },
  category_id: {
    type: Number,
    default: null,
    post: val => val !== null ? +val : undefined,
    label: val => val === 0 ? 'New' : Vue.filter('productCategory')(val)
  },
  'q[pfd]': {
    type: Boolean,
    default: null,
    post: val => val || undefined,
    label: val => 'Express-Only'
  },
  'q[pfg]': {
    type: Boolean,
    default: null,
    post: val => !!val || undefined,
    label: val => 'Grow only'
  },
  'q[product_availability]': {
    type: String,
    default: null,
    post: val => val || undefined,
    label: val => 'In stock'
  },
  'q[vendor_company_id]': {
    type: Number,
    default: null,
    invisible: true,
    post: val => val !== null ? +val : undefined,
    label: val => null
  },
  'q[region_type]': {
    type: String,
    default: null,
    post: val => val || undefined,
    label: val => null
  },
  'q[tag_ids]': {
    type: Array,
    subType: Number,
    default: () => [],
    label: val => Vue.filter('productTag')(val)
  },
  'q[product_quality]': {
    type: Array,
    subType: Number,
    default: () => [],
    label: val => Vue.filter('productQuality')(val)
  },
  'q[package_size]': {
    type: Array,
    subType: Number,
    default: () => [],
    label: val => Vue.filter('packageSize')(val)
  },
  'q[product_type_id]': {
    type: Number,
    // subType: Number,
    default: null,
    post: val => val ? +val : undefined,
    label: val => Vue.filter('productType')(val)
  },
  'q[address_state_id]': {
    type: Number,
    default: null,
    post: val => +val || undefined,
    label: val => Vue.filter('addressState')(val)
  },
  promo_types: {
    type: Array,
    default: () => [],
    label: val => Vue.filter('promotionType')(val)
  }
}

export function getDefaultQuery () {
  return Object
    .keys(QUERY_STRUCTURE)
    .reduce((res, q) => {
      const def = QUERY_STRUCTURE[q]
      switch (def.type) {
        case Array:
        case Object:
          if (def.default instanceof Function) res[q] = def.default()
          else res[q] = def.default
          break
        default:
          res[q] = def.default
      }
      return res
    }, {})
}

export function serializeQuery (query = {}) {
  const to = Object
    .keys(query)
    .reduce((res, q) => {
      const def = QUERY_STRUCTURE[q]
      let val = query[q]
      if (def && def.post) {
        val = def.post(val)
        // remove undefined query value
        if (typeof val !== 'undefined') res[q] = val
      } else {
        res[q] = val
      }
      return res
    }, {})
  return to
}

export function normalizeQueryValue (key, _val) {
  const def = QUERY_STRUCTURE[key]
  if (!def) return _val
  let res = null
  const val = def.post ? def.post(_val) : _val
  if (val === undefined) return val
  switch (def.type) {
    case Number:
      return +val
    case Array:
      res = Array.isArray(val) ? val : [val]
      switch (def.subType) {
        case Number:
          return res.map(i => +i)
        default:
          return res
      }
    default:
      return val
  }
}

export function deserializeQuery (query = {}) {
  return {
    ...getDefaultQuery(),
    ...Object
      .keys(query)
      .reduce((res, key) => {
        const val = normalizeQueryValue(key, query[key])
        if (val !== undefined) res[key] = val
        return res
      }, {})
  }
}
