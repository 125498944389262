<template lang="pug">
.catalog-header-bar
  .top.flex.inner-gap(:class="{searching}")
    .logo
      nuxt-link(:to="{ name: 'index' }" title="Pod")
        img(src="/img/logo.svg?pod")
    .search
      client-only
        search-box(ref="search")
    a.search-toggler(href="#" @click.prevent="searching = !searching")
      i.pf-icon.bx.bx-x(v-if="searching")
      i.pf-icon.bx.bx-search(v-else)

    .notification(v-if="showNotificationBox")
      notification-box

    .user
      user-box

  .bottom.flex.inner-gap
    .expander

    .menu
      a.flex(href="#"
        @click.prevent="setPopover(!popover)"
        @mouseenter="setPopover(true)"
        @mouseleave="setPopover(false)") Catalog
        i.bx.bx-chevron-down.bx-5

      template(v-if="isVendor")
        a(:href="`${SUPPORT_URL}`" target="_blank" @click="$amplitude.track('CLICK_BRAND_GUIDE')") Support
      template(v-if="isHeadBuyer || (isBuyer && !isPreApprovalBuyer)")
        nuxt-link(
          v-if="!isHeadBuyer"
          :to="{ name: 'promotions' }"
          title="promotions"
          v-click="() => $amplitude.track('CLICK_PROMOTIONS')") Promotions
        nuxt-link(
          :to="{ name: 'order-guide' }"
          title="Order guide"
          v-click="() => $amplitude.track('CLICK_ORDER_GUIDE')") Order guide
        nuxt-link(
          :to="{ name: 'favorites' }"
          title="Favorites"
          v-click="() => $amplitude.track('CLICK_FAVORITES')") Favorites
      template(v-if="isBuyer || isHeadBuyer")
        nuxt-link.invite-brands(
          :to="{ name: 'brands-invite' }"
          title="Invite brands"
          v-click="() => $amplitude.track('CLICK_REFER_BRAND')") Refer a Brand
        a.weekly-promo(
          v-if="weeklyPromo"
          href="#"
          title="Weekly promo"
          @click.prevent="openWeeklyPromo")
          strong Weekly promo
          span.ml-1 🎉

  transition(name="fade")
    .catalog--popover(
      v-if="popover"
      @mouseenter="setPopover(true)"
      @mouseleave="setPopover(false)")
      .catalog--content
        el-row(:gutter="30")
          el-col(:span="3")
            .title Explore
            .content
              .content--item
                nuxt-link.name(
                  :to="{ name: 'products' }"
                  v-click="() => { setPopover(false) }") All Catalog
              .content--item
                nuxt-link.name(
                  :to="{ name: 'products-new' }"
                  v-click="() => { setPopover(false) }") New Products
              .content--item
                nuxt-link.name(
                  :to="{ name: 'brands-new' }"
                  v-click="() => { setPopover(false) }") New Brands

          el-col.categories(:span="3")
            .title Categories
            .content
              nuxt-link.content--item(
                v-for="category in categories"
                :key="category.id"
                :to="$links.catalog({ query: { category_id: category.id }})"
                v-click="() => { setPopover(false) }")
                span.name(@mouseenter="categorySelected = category") {{ category.name }}
          el-col.sub-cate(:span="6")
            el-row(:gutter="30")
              el-col(:span="12" v-for="subCate in productTypes" :key="subCate.id")
                nuxt-link.content--item(
                  :to="$links.catalog({ query: { category_id: categorySelected.id, 'q[product_type_id]': subCate.id  }})"
                  v-click="() => { setPopover(false) }")
                  span.name {{ subCate.name }}
          el-col(:span="12")
            el-card.brand-card(shadow="never" v-if="brands.length")
              .title
                nuxt-link.linked(
                  :to="{ name: 'brands-new', query: { category_id: categorySelected.id } }"
                  v-click="() => { setPopover(false) }") New {{ categorySelected.name }} Brands ->
              .catalog--grid
                CatalogCard(
                  v-for="(brand, key) of brands"
                  type="brand"
                  :key="`brand-${key}`"
                  :data="brand"
                  v-click="() => { setPopover(false) }")
            el-card.product-card(shadow="never" v-if="products.length")
              .title
                nuxt-link.linked(
                  :to="{ name: 'products-new', query: { category_id: categorySelected.id } }"
                  v-click="() => { setPopover(false) }") New {{ categorySelected.name }} Products ->
              .catalog--grid
                CatalogCard(
                  v-for="(product, key) of products"
                  type="product"
                  :key="`product-${key}`"
                  :data="product"
                  v-click="() => { setPopover(false) }")
</template>

<script>
import { mapGetters } from 'vuex'
import NotificationBox from './NotificationBox'
import SearchBox from './SearchBox'
import UserBox from './UserBox'
import CatalogCard from './CatalogCard'
import WeeklyPromoModal from '~/components/modals/WeeklyPromoModal'
import { DEBOUNCE_DELAY, SUPPORT_URL } from '~/settings'
import { debounce } from 'lodash'

export default {
  components: {
    NotificationBox,
    SearchBox,
    UserBox,
    WeeklyPromoModal,
    CatalogCard
  },

  props: {
    showCategories: {
      type: Boolean,
      default: true
    },
    showNotification: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      popover: false,
      searching: false,
      state: null,
      showStates: false,
      categorySelected: null,
      SUPPORT_URL,
      setPopover: null,
      brands: [],
      products: [],
      loading: {
        brands: false,
        products: false
      }
    }
  },

   fetch () {
    if (!this.categorySelected) this.categorySelected = this.categories?.[0]

     return Promise.all([
       this.getBrandsByCategory(),
       this.getProductsByCategory()
     ])
  },

  computed: {
    ...mapGetters({
      addressStates: 'master/addressStates',
      isBroker: 'authentication/isBroker',
      isVendor: 'authentication/isVendor',
      isHeadBuyer: 'authentication/isHeadBuyer',
      isBuyer: 'authentication/isBuyer',
      isPreApprovalBuyer: 'authentication/isPreApprovalBuyer',
      weeklyPromo: 'master/weeklyPromo',
      categories: 'master/productCategories'
    }),

    showNotificationBox () {
      return this.showNotification && this.isVendor && !this.isBroker
    },

    productTypes () {
      return this.categorySelected?.product_types || []
    }
  },

  watch: {
    searching (val) {
      if (!val) return
      this.$nextTick(() => {
        this.$refs.search && this.$refs.search.focus()
      })
    },

    productTypes () {
      this.brands = Array.from({ length: 5 })
      this.products = Array.from({ length: 5 })
      this.getBrandsByCategoryDebounced()
      this.getProductsByCategoryDebounced()
    }
  },

  mounted () {
    this.setPopover = debounce((val) => { this.popover = val }, 300)
    this.getBrandsByCategoryDebounced = debounce(this.getBrandsByCategory, DEBOUNCE_DELAY)
    this.getProductsByCategoryDebounced = debounce(this.getProductsByCategory, DEBOUNCE_DELAY)
  },

  methods: {
    getBrandsByCategory () {
      this.loading.brands = true

      return this.$api.catalog.searchBrands({
        per_page: 5,
        product_category_ids: [this.categorySelected?.id],
        new_brand: true
      }).then((res) => {
        this.brands = res.results
      }).finally(() => {
        this.loading.brands = false
      })
    },

    getProductsByCategory () {
      this.loading.products = true

     return this.$api.catalog.searchProducts({
        per_page: 5,
        category_id: this.categorySelected?.id,
        new_brand: true
      }).then((res) => {
        this.products = res.results
      }).finally(() => {
       this.loading.products = false
     })
    },

    openWeeklyPromo () {
      this.$amplitude.track('CLICK_WEEKLY_PROMO')
      this.$dialogs.open({
        parent: this,
        component: WeeklyPromoModal
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.catalog--popover {
  position: absolute;
  top: calc(100% + 1px);
  left: 0;
  width: 100%;
  height: fit-content;
  z-index: 9999;
  background: white;
  padding: 1em 2em 1em 0;
  border-radius: 4px;
  border: $--border-light;
  overflow-x: auto;

  @include media(md) {
    padding-left: 260px;
  }

  .catalog--content {
    min-width: 1000px;
  }

  .title {
    display: block;
    font-weight: bold;
    margin-bottom: 1em;

  }

  .categories {
    border-right: $--border-light;
    max-height: 650px;
    overflow-y: auto;
  }

  .sub-cate {
    min-height: 30px;
  }

  .content {
    &--item {
      display: block;
      padding: 0.5em 0;

      .name {
        &:hover {
          color: $--color-primary;
          cursor: pointer;
        }
      }

    }
  }

  .brand-card, .product-card {
    background: #f4f6f8;

    .catalog--grid {
      display: grid;
      grid-gap: 12px 10px;
      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
      grid-auto-rows: 0;
      grid-template-rows: 1fr;
    }
  }
}
</style>

<style lang="scss" scoped>
@keyframes bubble {
  0% { transform: scale(1); }
  20% { transform: scale(1.5); }
  100% { transform: scale(1); }
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
.catalog-header-bar {
  $divider: $--border-light;
  position: relative;
  background: rgba(white, .95);
  border-bottom: $divider;
  a {
    outline: none;
  }
  .top {
    align-items: center;
    min-height: 50px;
    @include media(md) {
      min-height: 58px;
    }
    &.searching {
      .logo {
        display: none;
      }
      .search {
        display: block;
      }
      .user {
        display: none;
      }
    }
    .logo {
      padding: 10px 0;
      flex: 1;
      @include media(sm) {
        flex: 0 120px;
        margin-right: 20px;
      }
      @include media(md) {
        flex: 200px 0;
        margin-right: 30px;
      }
      img {
        display: block;
        width: 120px;
        @include media(md) {
          width: 160px;
        }
      }
    }
    .search {
      flex: 1;
      display: none;
      @include media(sm) {
        display: block;
      }
    }
    .search-toggler {
      display: block;
      padding: 12px;
      margin-right: -10px;
      @include media(sm) {
        display: none;
      }
      > span {
        cursor: pointer;
      }
    }
    .user {
      margin-left: 10px;
      text-align: right;
      @include media(md) {
        margin-left: 1em;
      }
    }
    .notification {
      margin-left: 10px;
      text-align: right;
      @include media(md) {
        margin-left: 1em;
      }
    }
  }
  .bottom {
    border-top: $divider;
    font-size: 12px;
    @include media(sm-down) {
      padding-left: 0;
      padding-right: 0;
    }
    @include media(md) {
      font-size: 13px;
    }
    .expander {
      margin-right: 1.5em;
      display: none;
      @include media(md) {
        display: block;
        margin-right: 30px;
        flex: 0 200px;
      }
    }
    .menu {
      display: flex;
      overflow-x: scroll;
      white-space: nowrap;
      @include no-scroller();
      > a {
        &.invite-brands {
          color: $--color-primary;
        }
        &.weekly-promo {
          strong {
            background-image: linear-gradient(
                -225deg,
                #231557 0%,
                #44107a 29%,
                $--color-danger 67%,
                $--color-highlight 100%
            );
            background-size: 200% auto;
            background-clip: text;
            text-fill-color: transparent;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            animation: textclip 2s linear infinite;
            animation-direction: reverse;
          }
          span {
            display: inline-block;
            animation: bubble infinite 2s;
            animation-delay: 1s;
          }
        }
      }
      > * {
        min-width: auto;
        padding: .6em 0;
        color: $--color-text-regular;
        line-height: 16px;
        @include media(sm-down) {
          &:first-child {
            margin-left: 1em;
          }
          &:last-child {
            margin-right: 1em;
          }
        }
        a {
          color: $--color-text-regular;
        }
        &:not(:last-child) {
          margin-right: 1.5em;
        }
        &:hover {
          color: $--color-primary;
        }
      }
    }
  }
  .address-state-select {
    font-size: 13px;
    ::v-deep {
      .el-select {
        &__caret {
          line-height: 14px;
        }
      }
      .el-input {
        display: flex;
        align-items: center;
        > * {
          min-width: 0;
          height: 36px;
        }
        input {
          order: 2;
          font-size: 13px;
        }
        &__prefix {
          position: relative;
          font-size: 13px;
          text-align: left;
          white-space: nowrap;
          margin-right: .5em;
          display: flex;
          align-items: center;
        }
        &__inner {
          border: none;
          padding: 0;
        }
      }
    }
  }
}
</style>
